<template>
  <div class="main">
    <Top></Top>
    <div class="content flex">
      <Left></Left>
      <div class="main-right flex1">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import Left from "./left.vue";
import Top from "./top.vue";
export default {
  components: {
    Left,
    Top,
  },
  data() {
    return {};
  },
  created() {},
};
</script>
<style scoped>
.main {
  height: 100vh;
}
.main-right {
  min-height: calc(100vh - 60px);
  background-color: #f2f1f1;
  overflow: hidden;
  overflow-y: scroll;
  padding: 20px;
  box-sizing: border-box;
}
</style>