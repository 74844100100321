<template>
  <div class="left-w f-ccc f12">
    <div
      @click="goPage(it)"
      class="h-m flex flex-column ac jc po rl"
      v-for="(it, index) in menu"
      :key="index"
    >
      <div
        class="text-center mgb6 icon flex ac jc bold"
        v-if="it.type == 'text'"
      >
        {{ it.icon }}
      </div>
      <div
        class="text-center mgb6 f28"
        :class="it.icon"
        v-else-if="it.type == 'icon'"
      ></div>
      <div class="text-center">{{ it.text }}</div>
      <div v-if="it.children && it.children.length > 0" class="abl menu-right">
        <div
          @click.stop="goPage(c)"
          v-for="(c, ci) in it.children"
          :key="ci"
          class="f-title f-gray"
        >
          {{ c.text }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menu: [
        {
          type: "icon",
          icon: "el-icon-user",
          text: "会员管理",
          router: "BackendMemberIndex", //菜单
          children: [
            {
              text: "会员列表",
              router: "BackendMemberIndex",
            },
            {
              text: "授权店铺列表",
              router: "BackendShopsIndex",
            },
            {
              text: "会员购买记录",
              router: "BackendMemberBuyIndex",
            },
            {
              text: "用户操作日志",
              router: "BackendMemberOpIndex",
            },
          ],
        },
        {
          type: "icon",
          icon: "el-icon-picture-outline-round",
          text: "运营管理",
          router: "BackendSetMealIndex", //菜单
          children: [
            {
              text: "套餐列表",
              router: "BackendSetMealIndex",
            },
            {
              text: "用户访问",
              router: "BackendVisiIndex",
            },
            {
              text: "用户访问明细",
              router: "BackendVisiDetail",
            },
          ]
        },
        {
          type: "icon",
          icon: "el-icon-coin",
          text: "系统管理",
          router: "BackendSystemModule", //菜单
          children: [
            {
              text: "系统模块",
              router: "BackendSystemModule",
            },
            {
              text: "系统配置",
              router: "BackendConfigIndex",
            },
            {
              text: "数据库",
              router: "BackendDatabaseIndex",
            },
            {
              text: "语言包",
              router: "BackendLangIndex",
            }
          ]
        },
        {
          type: "icon",
          icon: "el-icon-s-shop",
          text: "系统监控",
          router: "BackendShopMonitorIndex", //菜单
          children: [
            {
              text: "店铺监控",
              router: "BackendShopMonitorIndex",
            },
          ]
        },
      ],
    };
  },
  methods: {
    goPage: function (menu) {
      if (menu.router != "") {
        var t = new Date().getTime();
        this.$router.push({
          name: menu.router,
          query: {
            t: t,
          },
        });
      }
    },
  },
};
</script>
<style scoped>
.left-w {
  min-height: calc(100vh - 60px);
  width: 100px;
  background-color: #545c64;
}
.h-m {
  height: 60px;
  padding: 10px 0px;
}
.icon {
  border: 2px solid #ccc;
  border-radius: 100%;
  width: 28px;
  height: 28px;
}
.h-m:hover {
  background-color: #404a50;
}
.h-m:hover .menu-right {
  display: block;
}
.menu-right {
  top: 0px;
  left: 100px;
  width: 200px;
  background-color: #fff;
  box-shadow: 0 0 10px #ddd;
  display: none;
  z-index: 1000;
}
.menu-right > div {
  padding: 14px 20px;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
}
.menu-right > div:hover {
  outline: 0;
  background-color: #ecf5ff;
}
</style>