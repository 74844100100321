<template>
  <Main>
    <el-card class="box-card">
      <el-form :inline="true" class="demo-form-inline" size="mini">
        <el-form-item>
          <el-button type="primary" @click="openmodule()">新增模块</el-button>
        </el-form-item>
        <el-divider direction="vertical"></el-divider>
        <el-form-item>
          <el-input placeholder="请输入模块名" clearable v-model="where.module_name">
          </el-input>
        </el-form-item>
        <el-form-item label="平台">
          <el-radio-group v-model="where.source" @change="$emit('refresh')">
            <el-radio-button :label="1">Shopee</el-radio-button>
            <el-radio-button :label="2">Lazada</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button
            @click="$emit('refresh')"
            type="primary"
            icon="el-icon-search"
            circle
          ></el-button>
        </el-form-item>
      </el-form>
      <div>
        <slot></slot>
      </div>
    </el-card>
    <Moduleform ref="module" @refresh="$emit('refresh')"></Moduleform>
  </Main>
</template>
<script>
import Main from "@/components/backend/common/main.vue";
import Moduleform from "@/components/backend/system/module.vue";
export default {
  components: {
    Main,
    Moduleform,
  },
  props: {
    where: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    openmodule: function () {
      this.$refs.module.show();
    },
  },
};
</script>